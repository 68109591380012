import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import SEO from "../components/seo"
import heroImage from "../images/backgrounds/webinars_bg.jpg"
//const pageURL = document.location.href.split('#')[1];
import { withPreview } from "gatsby-source-prismic"
import { Link } from "gatsby"

const WebinarBlock = props => {
  const data = props.data
  const background = data.node.data.thumbnail.fixed.src

  const link = data.node.data.is_external
    ? data.node.data.link_to_external_page.url
    : `/webinars/${data.node.uid}`

  return (
    <div className="webinarBlockWrapper uk-light">
      <>
        <div className="border">
          <div
            className="background"
            style={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              color: "black",
              display: "flex",
              backgroundPosition: `center center`,
              alignItems: `stretch`,
              height: `250px`,
            }}
          >
            <div className="backdrop">
              <h4>{data.node.data.title}</h4>
              <p>
                <Link to={link}>
                  View video <span uk-icon="arrow-right"></span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

class Webinars extends React.Component {
  constructor(props) {
    super(props)
    this.location = props.location
    this.data = props.data
  }

  componentDidMount() {
    // at first here was a hash, then I moved it to separate url, e.g.:
    // /webinars#ride-the-subscription-lifecycle-to-success ->
    // /webinars/ride-the-subscription-lifecycle-to-success
    const thehash =
      this.location.hash && this.location.hash.length > 0 ? this.location.hash.substring(1) : null
    if (!thehash) return
    this.data.allPrismicWebinars.edges.forEach(webinar => {
      if (thehash === webinar.node.uid) navigate(`./${webinar.node.uid}`, { replace: true })
    })
  }
  //const Webinars = ({data}) => {
  render() {
    return (
      <Layout>
        <SEO
          title="Payment Recovery and Payment Intelligence Webinars"
          description="Explore Vindicia’s on-demand webinars on payment recovery, customer retention management, and churn management. Enhance your knowledge with our expert insights."
          keywords="revenue recovery, payment recovery, customer retention management, churn management, failed payments, subscription billing, subscription retention, recurring revenue, webinars"
        />
        <Header
          image={heroImage}
          title="On-Demand Webinars"
          subtitle="Explore our on-demand webinars for expert advice, industry trends, and best practices in payment recovery and payment intelligence to boost your subscription business success."
        />
        <div className="uk-container uk-margin-large">
          <div className="uk-child-width-1-3@m uk-flex uk-grid" data-uk-grid="true">
            {this.data.allPrismicWebinars.edges.map((webinar, i) => (
              <WebinarBlock key={`webinar-${i}`} data={webinar} index={i} />
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export default withPreview(Webinars)

export const webinarsQuery = graphql`
  {
    allPrismicWebinars(sort: { order: DESC, fields: data___publish_date }) {
      edges {
        node {
          uid
          data {
            title
            is_external
            link_to_external_page {
              target
              url
            }
            thumbnail {
              fixed(width: 400) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            description {
              html
            }
            vimeo_id
            is_gated
          }
        }
      }
    }
  }
`
